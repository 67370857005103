export default {
  init() {
    // JavaScript to be fired on all pages
      
    // sliders
    $('.header_slider').each(function(){
      $( this ).slick({
        lazyLoad: 'progressive',
        autoplay: true,
        pauseOnHover: true,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        speed: 3500,
        fade: true,
        arrows: true
      });
    });

    // sliders
    $('.mijlpalen').each(function(){
      $( this ).slick({
        lazyLoad: 'progressive',
        autoplay: false,
        pauseOnHover: true,
        dots: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: false,
        speed: 300,
        fade: false,
        arrows: true,
        mobileFirst: true,
        responsive: [
          {
            breakpoint: 1300,
            settings: {
              slidesToShow: 6,
              slidesToScroll: 6,
            },
          },
          {
            breakpoint: 1100,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 5,
            },
          },
          {
            breakpoint: 1000,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
        ],
      });
    });

    // Initialize Fancybox
    $('[data-fancybox]').fancybox({
      // Fancybox options
    });

  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  
     // header change on scroll
     $(window).scroll(function(){
      var sticky = $('.banner'),
          scroll = $(window).scrollTop();
    
      if (scroll >= 32){
        sticky.addClass('fixed');
      }else{
        sticky.removeClass('fixed');
      }
    });

    // hamburger menu
    // menu toggle mobile 
    $('.icon_burger').on( 'click', function() {
      $('.top-menu').toggleClass('top-animate');
      $('.mid-menu').toggleClass('mid-animate');
      $('.bottom-menu').toggleClass('bottom-animate');

      $('.nav-primary').toggleClass('active-menu');
      
      $('body').toggleClass('noscroll');
    });

    $('.menu-item-has-children .svg-inline--fa').on( 'click', function() {
      
      $( this ).parent().find( '.sub-menu' ).toggleClass( 'active-sub-menu' );

    });

  },
};

function windstats() {

  let c = document.getElementById("bg-meter");

  if (c !== null) {  
  // show loader
    $.ajax({
      url  : stats.ajaxurl,
      type : 'get',
      data : {
        action 			: 'turbine_stats',
        security		: stats.ajax_nonce,
      },
      success : function( data ) {

        if(data) {
          var json = JSON.parse(data);
			
	          //console.log(json["result"]);

            $.each(json["result"], function(key, value){
                
              // max productie
              if(key === 'max_productie' ){
                $(".windmax_productie").val(value);
              }
              
              // Windrichting
                if(key === 'windrichting' ){
                  $(".windrichting .subtitel").text(  degToCompassEn(value) );
                  $(".windrichting .windrichting_icon").css({transform: 'rotate(' + (value) + 'deg)'})
                }

              // Windsnelheid
                if(key === 'windkracht' ){
                  $(".windkracht .subtitel").html(getBeaufortScale(value) + " bft " + value.toLocaleString("nl") + " m/s");
                  $(".windkracht .windkracht_icon").css({transform: 'rotate(' + (Math.min(6, value) * 22.5) + 'deg)'})
                }

              // Huishoudens
                if(key === 'huishoudens' ){
                  $(".huishoudens .subtitel").html(value.toLocaleString("nl"));
                }

              // Windmolens online
                 if(key === 'windturbines' ){
                  $(".windturbine_online .subtitel").html(value.toLocaleString("nl"));
                }

              // Windmolens online
                if(key === 'totale_productie' ){
                  meter_productie(value);
                }
                console.log(value); // check this for debug and get an idea
            });
        }
        else {
            // alert(data.message); 
            //console.log( data );
        }
      },
      error: function (textStatus, errorThrown) {

        console.log('Er ging iets fout' + errorThrown + textStatus);
      },
    });
  }
}

function degToCompassEn(deg) {
  let names = ["N", "NNO", "NO", "ONO", "O", "OZO", "ZO", "ZZO", "Z", "ZZW", "ZW", "WZW", "W", "WNW", "NW", "NNW"];
  return names[parseInt((deg / 22.5 + 0.5) % 16)];
}

function getBeaufortScale(windspeed) {
  let limits = [0.3, 1.5, 3.3, 5.5, 7.9, 10.7, 13.8, 17.1, 20.7, 24.4, 28.4, 32.6];
  for (let i = 0; i < limits.length; i++) {
      if (windspeed < limits[i]) {
          return i
      }
  }
  return "12";
}


function grote_meter() {
  let c = document.getElementById("bg-meter");

  if (c !== null) {  
    let ctx = c.getContext("2d");
    ctx.clearRect(0, 0, c.width, c.height);

    ctx.beginPath();
    ctx.arc(500, 500, 400, 0, 2 * Math.PI);
    ctx.lineWidth     = 50;
    ctx.strokeStyle   = "#005A81";
    ctx.stroke();
  }
}

function meter_productie( productie ) {

  let c = document.getElementById("meter");

  if (c !== null) {  
    var productie_max = 263.2; //$(".windmax_productie").val();

    $(".windproductie .titel").text(productie);

    let ctx = c.getContext("2d");
    ctx.clearRect(0, 0, c.width, c.height);
    ctx.beginPath();
    ctx.arc(500, 500, 400, 0, 2 * Math.PI * productie / productie_max);
    ctx.lineWidth     = 110;
    ctx.strokeStyle   = "#7B982A";
    ctx.stroke();
  }
}

grote_meter();
meter_productie(0);

windstats();

var intervalId = window.setInterval(function(){
  windstats();
}, 10000);